import { FormEvent, useState } from "react"
import styled from "styled-components"

import {
  IAddress,
  IEmergencyContact,
  THome,
} from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { MTextArea } from "src/ui/MTextArea/MTextArea"
import { MTextField } from "src/ui/MTextField/MTextField"
import { PhoneNumberInput } from "src/ui/PhoneNumberInput/PhoneNumberInput"
import { validatePhoneNumber } from "src/ui/PhoneNumberInput/phoneNumberUtil"
import { spacing } from "src/ui/spacing"

export const HOME_EMERGENCY_CONTACT_FORM_ID = "home_emergency_contact_form_id"
export function HomeEmergencyContactStep({
  onSuccess,
  setupForGuardService,
  emergencyContact,
  notes,
}: {
  onSuccess: (
    fields: Partial<IEmergencyContact> & Pick<IAddress, "notes">
  ) => void
  setupForGuardService: boolean
  emergencyContact: THome["emergency_contact"]
  notes: IAddress["notes"]
}) {
  const emergencyContactInitial: NonNullable<THome["emergency_contact"]> =
    emergencyContact ?? {
      name: "",
      phone_number: "",
    }
  const { t, langKeys } = useTranslate()
  const [phoneError, setPhoneError] = useState(false)
  const [formFields, setFormFields] = useState<FormFields>({
    ...emergencyContactInitial,
    notes,
  })

  const updateFormFields = (fields: FormFields) =>
    setFormFields((prev) => ({
      ...prev,
      ...fields,
    }))

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const formIsRequired = setupForGuardService
    if (formIsRequired && !formFields.name) {
      return false
    }
    if (
      (formIsRequired && !formFields.phone_number) ||
      (formFields.phone_number && !validatePhoneNumber(formFields.phone_number))
    ) {
      setPhoneError(true)
      return false
    }
    onSuccess({
      name: formFields.name,
      phone_number: formFields.phone_number,
      notes: formFields.notes,
    })
  }

  return (
    <form id={HOME_EMERGENCY_CONTACT_FORM_ID} onSubmit={handleSubmit}>
      <Notes>
        <MText variant="heading3">
          {setupForGuardService
            ? t(langKeys.response_service_home_note_title)
            : t(langKeys.team_contacts_home_note_title)}
        </MText>
        <MText variant="body" marginBottom={spacing.L}>
          {t(langKeys.response_service_home_note_description)}
        </MText>
        <MTextArea
          autoComplete="notes"
          placeholder={t(
            langKeys.response_service_home_note_description_placeholder
          )}
          name="notes"
          minRows={4}
          maxRows={4}
          value={formFields.notes ?? ""}
          onChange={(value) => updateFormFields({ notes: value })}
        />
      </Notes>
      <div>
        <MText variant="heading3">
          {t(langKeys.home_emergency_contact_title)}
        </MText>
        <MText variant="body">{t(langKeys.home_emergency_contact_body)}</MText>
        <Grid>
          <MTextField
            required={!!setupForGuardService}
            label={t(langKeys.name)}
            value={formFields?.name ?? ""}
            onChange={(value) => updateFormFields({ name: value })}
          />
          <PhoneNumberInput
            required={!!setupForGuardService}
            value={formFields.phone_number}
            onChange={(value) => updateFormFields({ phone_number: value })}
            error={phoneError}
            onBlur={() => {
              if (!validatePhoneNumber(formFields.phone_number ?? "")) {
                setPhoneError(true)
              }
            }}
            onFocus={() => setPhoneError(false)}
          />
        </Grid>
      </div>
    </form>
  )
}

type FormFields = Partial<IEmergencyContact> & Pick<IAddress, "notes">

const Grid = styled.div`
  display: grid;
  gap: ${spacing.M};
  margin-top: ${spacing.L};
`

const Notes = styled.div`
  display: grid;
  margin-bottom: ${spacing.M};
`
